import { GoArrowUpRight } from "react-icons/go";
export default function Stats() {
    return (
        <div className="bg-[#564A4A] max-w-6xl sm:mx-auto mx-5 my-10 p-10 rounded-2xl flex justify-center gap-7 flex-wrap items-center">
            <div className="bg-[#712C30] sm:h-[220px] p-3 sm:pb-0 pb-10 text-white rounded-2xl text-center">
                <div className="flex justify-between items-center">
                    <div>
                        <h1 className="font-bold text-6xl mt-5">3K+</h1>
                        <p>Satisfied Customers</p>
                    </div>
                    <div className="bg-white p-3 w-[50px] h-[50px] text-center flex items-center justify-center rounded-full">
                        <GoArrowUpRight className="text-[#712C30] text-3xl" />
                    </div>
                </div>
                <p className="text-sm mt-[50px]">Our awesome clients are industry experts</p>
            </div>
            <div className="bg-white rounded-2xl h-[220px] w-[230px] text-center">
                <h1 className="font-bold text-6xl mt-16">12+</h1>
                <p className="text-sm">Consultants Experience</p>
            </div>
            <div className="bg-white rounded-2xl h-[220px] w-[230px] text-center">
                <h1 className="font-bold text-6xl mt-16">500+</h1>
                <p className="text-sm">Growth Implementations</p>
            </div>
            <div className="bg-white rounded-2xl h-[220px] w-[230px] text-center">
                <h1 className="font-bold text-6xl mt-16">350+</h1>
                <p className="text-sm">Business Plans</p>
            </div>

        </div>
    )
}