import React from "react";
import proj1 from "../assets/proj1.png";
import proj2 from "../assets/proj2.png";
import proj3 from "../assets/proj3.png";

const projects = [
    {
        id: 1,
        title: "Improving Livelihoods and Empowering Vulnerable Households",
        description: "PARTNERED WITH SOS CHILDREN VILLAGES",
        image: proj1, // Replace with actual image URLs
    },
    {
        id: 2,
        title: "Enhancing Customer Satisfaction",
        description: "CORPORATE TRAINING FOR SAZY PETROLEUM & CREAM 2GO",
        image: proj2,
    },
    {
        id: 3,
        title: "Business Owners Hangout",
        description: "HOSTED BY MINDSHIFT CONSULTANCY AND SERVICES",
        image: proj3,
    },
];

const ProjectsCompleted = () => {
    return (
        <section id="blog" className="py-16">
            <div className="max-w-6xl mx-auto px-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-3xl font-bold text-gray-800">
                        Projects We <br /> <span className="text-[#712C30]">Have Completed</span>
                    </h2>
                    <button className="bg-[#712C30] text-white py-2 px-4 rounded hover:bg-red-800">
                        Start New Project
                    </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {projects.map((project) => (
                        <div
                            key={project.id}
                            className="bg-white rounded-lg shadow-lg overflow-hidden"
                        >
                            <img
                                src={project.image}
                                alt={project.title}
                                className="w-full h-56 object-cover"
                            />
                            <div className="p-4">
                                <h3 className="text-lg font-semibold text-gray-800">
                                    {project.title}
                                </h3>
                                <p className="text-sm text-gray-600 mt-2">{project.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ProjectsCompleted;
