import React from "react";
import bg from "../assets/hero.png"
import { IoIosArrowForward } from "react-icons/io";

import { motion } from "framer-motion";

function Hero() {
  return (
    <div id="home" className="h-[500px] bg-cover bg-center" style={{
      backgroundImage: `url(${bg})`
    }}>
      <div className="text-white pt-16 max-w-[700px] mx-auto ">

        <div className="flex flex-col items-center sm:mx-0 mx-2 space-y-3 pt-20">
          <motion.div
            initial={{ opacity: 0 }} // Initial state
            animate={{ opacity: 1 }} // Final state
            transition={{ delay: 2, duration: 1 }} // Delay of 2 seconds, duration of 1 second
            className="md:text-5xl 2xl:text-5xl text-center text-black text-3xl font-bold "
          >
            Are you a <span className="text-[#712C30]">BUSINESS OWNER?</span>
          </motion.div>

          <div className=" max-w-[500px] mx-auto">
            <motion.h1
              initial={{ opacity: 0 }} // Initial state
              animate={{ opacity: 1 }} // Final state
              transition={{ delay: 2, duration: 1 }} // Delay of 2 seconds, duration of 1 second
              className="top-0 left-0 text-sm text-center text-black font-semibold z-10"
            >
              Whether you are looking to optimize operations or ehnance team performance, we align your vision with ours.
            </motion.h1>
          </div>
          <div className=" max-w-[500px] mx-auto">
            <motion.h1
              initial={{ opacity: 0 }} // Initial state
              animate={{ opacity: 1 }} // Final state
              transition={{ delay: 2, duration: 1 }} // Delay of 2 seconds, duration of 1 second
              className="top-0 left-0 text-xl text-center text-[#712C30] font-bold z-10"
            >
              BUILDING YOUR BUSINESS IS OUR BUSINESS.
            </motion.h1>
          </div>

          <div className="flex space-x-3 items-center  pt-5">
            <motion.button
              initial={{ opacity: 0 }} // Initial state
              animate={{ opacity: 1 }} // Final state
              transition={{ delay: 1, duration: 1 }} // Delay of 1 second, duration of 1 second
              className="md:text-lg text-sm border-2 flex space-x-2 bg-[#712C30] items-center px-4 py-2 rounded-2xl"
            >

              <div className="flex justify-between gap-5 items-center">
                <a href="https://outlook.office365.com/owa/calendar/MindshiftConsultancyandServices@mindshiftcs.com/bookings/" target="_blank" rel="noreferrer" className="text-md 2xl:text-xl font-semibold">
                  Book a consultation
                </a>
                <div className="bg-white p-2 rounded-2xl">
                  <IoIosArrowForward className="text-[#712C30] text-3xl" />
                </div>
              </div>
            </motion.button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Hero;

// linear-gradient(to right, #2D86FF 0%, #033AA7 100%)
// bg-gradient-to-b from-[#2D86FF] to[#033AA7]
