import React from 'react';
import one from '../assets/one.svg';
import two from '../assets/two.svg';
import three from '../assets/three.svg';
import four from '../assets/four.svg';
import five from '../assets/five.svg';
import six from '../assets/six.svg';
import seven from '../assets/seven.svg';
import eight from '../assets/eight.svg';
// import { Link } from 'react-router-dom';
const HostinOptions = () => {
    return (
        <div id='services' className="min-h-screen max-w-6xl mx-auto flex flex-col items-center justify-center p-4 mt-10" >

            <div className="mb-12 text-center">
                {/* <h1 className="text-xl text-[#712C30] rounded-full bg-white px-3 py-1 border-2 border-[#712C30] shadow-md w-[150px] mx-auto font-semibold">Our Services</h1> */}
                <p className="mt-2 text-xl sm:text-3xl font-bold">What we Serve <br />
                <span className='text-[#712C30]'>For Your Business</span></p>
            </div>
            <div className='flex flex-wrap justify-center gap-[30px] w-full'>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    <PricingCard
                        title="Business Advisory Services"
                        description="We organize and produce comprehensive documentation that supports your operations, boosts efficiency, and maintains compliance."
                        image={one}
                    />
                    <PricingCard
                        title="Human Capacity Development"
                        description="We develop and empower individuals by strengthening their skills and potential to fuel both personal and organizational success.     "
                        image={two}
                    />
                    <PricingCard
                        title="Project Management & M&E Services"
                        description="We expertly manage your projects from start to finish, ensuring seamless execution and delivering results on time and within budget."
                        image={five}
                    />
                    <PricingCard
                        title="Business Process Optimization"
                        description="We enhance operational efficiency by streamlining workflows, eliminating bottlenecks, and driving business performance."
                        image={three}
                    />
                    <PricingCard
                        title="Financial Advisory Services"
                        description="We provide expert financial advisory services to help businesses manage finances, optimize investments, and drive sustainable growth."
                        image={four}
                    />
                    <PricingCard
                        title="Marketing & Branding Services"
                        description="We craft distinctive and cohesive brand identities that capture your essence, connect with your audience, and leave a lasting impression."
                        image={five}
                    />
                    <PricingCard
                        title="Regulatory & Compliance Support"
                        description="We assist businesses in navigating complex regulatory environments and ensuring full compliance with industry standards and legal requirements."
                        image={six}
                    />
                    <PricingCard
                        title="Technology and Innovation Services"
                        description="We provide cutting-edge technology solutions and innovative strategies to drive business transformation and enhance operational efficiency."
                        image={eight}
                    />
                    <PricingCard
                        title="Coaching & Mentorship"
                        description="We offer personalized coaching and mentorship to empower individuals and teams, fostering professional growth and business success."
                        image={seven}
                    />
                </div>
            </div>

            {/* <div className='ml-auto mr-20 font-semibold mt-3 text-[#712C30]'>
                <Link className='hover:underline' to="/services">
                    View More
                </Link>
            </div> */}
        </div>
    );
};

const PricingCard = ({ title, description, price, features, image }) => {
    return (
        <div className="border border-gray-200 rounded-2xl w-[280px] h-[280px] shadow-md p-6 bg-[#F5F5F5]  hover:cursor-pointer">
            <div className='flex mt-2'>
                {image && <img src={image} alt={`${title} plan pic`} className="w-[30px]" />}
            </div>
            <div className='mt-5'>
                <h2 className="text-xl  font-semibold">{title}</h2>
                <p className="text-sm  font-medium">{description}</p>
            </div>
        </div>
    );
};

export default HostinOptions;
