import React from "react";

const testimonials = [
  {
    id: 1,
    name: "Powiede",
    title: "Founder, ATOP Cosmetics & Spa",
    feedback:
      "Mindshift's business development strategies were a game-changer for us. Their team uncovered new opportunities we hadn't considered and provided practical, actionable solutions. We're now thriving in markets we thought were out of reach.",
  },
  {
    id: 2,
    name: "Amanda Idahosa",
    title: "Founder, Zanga Cuisine",
    feedback:
      "Working with Mindshift Consultancy and Services was a game-changer for our business. Their business development expertise helped us identify new opportunities, streamline our strategies, and achieve sustainable growth. Highly professional and results-driven!",
  },
  {
    id: 3,
    name: "Akhigbe Eromosele",
    title: "Founder, KyrusRecycling",
    feedback:
      "Working with Mindshift Consultancy & Services transformed our business approach. Their strategic branding solutions gave us a unique edge, and their corporate training sessions were engaging and highly effective. We've seen a marked improvement in team productivity and brand recognition!",
  },
];

const Testimonials = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center mb-5">
          <h2 className="text-3xl font-bold text-gray-800">
            What Our  <br />
            <span className="text-[#712C30]">Satisfied Clients Say</span>
          </h2>
          <div className="flex justify-center gap-0 space-x-0">
            <button className="bg-gray-300 px-5 py-2 rounded-md hover:bg-gray-400">
              &#8592;
            </button>
            <button className="bg-[#712C30] text-white px-5 py-2 rounded-md hover:bg-red-800">
              &#8594;
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <div className="flex items-center gap-5">
                <div className="mb-4">
                  <img
                    src={`https://ui-avatars.com/api/?name=${testimonial.name}&background=random&size=64`}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full"
                  />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">
                    {testimonial.name}
                  </h3>
                  <p className="text-sm text-gray-500 mb-4 italic">{testimonial.title}</p>
                </div>
              </div>
              <p className="text-gray-700 text-sm italic">{testimonial.feedback}</p>
            </div>
          ))}
        </div>

      </div>
    </section>
  );
};

export default Testimonials;
