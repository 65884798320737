import React from "react";

const ContactSection = () => {
  return (
    <div id="contact" className="bg-gray-100 py-10">
      <div className="max-w-6xl mx-auto px-5">
        {/* Header */}
        <div className="text-center mb-10">
          <h2 className="text-3xl font-bold">
            We’re available <span className="text-[#712C30]">24/7</span>, ready to help you at
            anytime
          </h2>
          <button className="mt-3 text-sm text-gray-600 underline">See FAQs</button>
        </div>

        {/* Form and Map */}
        <div className="flex flex-col md:flex-row bg-white shadow-md rounded-lg overflow-hidden">
          {/* Form */}
          <div className="p-6 flex-1">
            <form className="space-y-4">
              <input
                type="text"
                placeholder="Name"
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-red-400"
              />
              <input
                type="email"
                placeholder="Email Address"
                className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-red-400"
              />
              <textarea
                placeholder="Message"
                className="w-full border border-gray-300 rounded-lg p-3 h-32 focus:outline-none focus:ring-2 focus:ring-red-400"
              />
              <div className="flex items-center space-x-2">
                <input type="checkbox" id="terms" className="h-4 w-4 text-[#712C30]" />
                <label htmlFor="terms" className="text-sm text-gray-600">
                  I agree with{" "}
                  <a href="https://mindshiftcs.com" className="text-[#712C30] underline">
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href="https://mindshiftcs.com" className="text-[#712C30] underline">
                    Privacy Policy
                  </a>
                </label>
              </div>
              <button
                type="submit"
                className="w-full bg-[#712C30] text-white py-3 rounded-lg hover:bg-red-900"
              >
                Send message
              </button>
            </form>
          </div>

          {/* Map */}
          <div className="flex-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.351009053178!2d5.6135065743772925!3d6.348576893641287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1040d310f0fecde1%3A0xbe969baea2da97a9!2s59%20I.C.E.%20Rd%2C%20Use%2C%20Benin%20City%20300102%2C%20Edo%2C%20Nigeria!5e0!3m2!1sen!2sus!4v1736852781827!5m2!1sen!2sus"
              width="100%"
              height="100%"
              title="Map"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              className="h-full w-full rounded-r-lg"
            ></iframe>
          </div>
        </div>

        {/* Contact Info */}
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-6 text-center text-white">
          <div className="bg-[#712C3078] p-5 rounded-lg">
            <p className="text-[#712C30] font-semibold">Message us</p>
            <p className="text-sm"><a href="mailto:info@mindshiftcs.com">info@mindshiftcs.com</a></p>
            <p className="text-sm"><a href="mailto:mindshiftcs@gmail.com">mindshiftcs@gmail.com</a></p>
          </div>
          <div className="bg-[#712C3078] p-5 rounded-lg">
            <p className="text-[#712C30] font-semibold">Call us</p>
            <p className="text-sm"><a href="tel:+2347067415264">+234 706 741 5264</a></p>
          </div>
          <div className="bg-[#712C3078] p-5 rounded-lg">
            <p className="text-[#712C30] font-semibold">Address</p>
            <p className="text-sm">59 ICE Rd, Benin City</p>
            <p className="text-sm">Edo State, Nigeria</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
