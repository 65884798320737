import React from "react";
import John from "../assets/john.png"
import Ken from "../assets/ken.png"
import Dara from "../assets/dara.png"
const teamMembers = [
  {
    id: 1,
    name: "JOHN IYOHA",
    title: "Head of Sales & Partnerships",
    image: John, // Replace with actual image URL
  },
  {
    id: 2,
    name: "KENNETH IGUODALA",
    title: "Chief of Operations / Human Resource Manager",
    image: Ken, // Replace with actual image URL
  },
  {
    id: 3,
    name: "DARA AKERELE",
    title: "Executive Admin / Head of Communications",
    image: Dara, // Replace with actual image URL
  },
];

const TeamSection = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4">
        {/* Section Header */}
        <h2 className="text-4xl font-bold text-gray-800 text-left mb-12">
          Meet Our <span className="text-[#712C30]">Team</span>
        </h2>

        {/* Team Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member) => (
            <div
              key={member.id}
              className="bg-white shadow-lg rounded-2xl overflow-hidden border-2"
            >
              {/* Image */}
              <img
                src={member.image}
                alt={member.name}
                className="w-full h-100 object-cover"
              />

              {/* Details */}
              <div className="p-6">
                <h3 className="text-lg font-bold text-gray-900">{member.name}</h3>
                <p className="text-sm text-[#712C30] italic">{member.title}</p>
              </div>

              {/* Icon */}
              {/* <div className="flex justify-end p-4">
                <button className="w-10 h-10 bg-red-700 text-white rounded-full flex items-center justify-center hover:bg-red-800">
                  &#8599; Arrow Icon
                </button>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
