import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

const MobileMenu = () => {
  const modalRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const pathMatchRoute = (route) => {
    return location.hash === route;
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeHamburger();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleHamburger = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeHamburger = () => {
    setIsMenuOpen(false);
  };

  const handleNavigate = (hash) => {
    navigate(hash);
    closeHamburger();
    const element = document.getElementById(hash.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex sm-custom:hidden cursor-pointer text-[30px]">
      <button onClick={toggleHamburger} aria-label="Toggle Menu">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M3 12h18M3 6h18M3 18h18" />
        </svg>
      </button>
      <div
        ref={modalRef}
        className={`fixed inset-0 h-full w-1/2 mt-[86px] flex items-center justify-center z-50 ${
          isMenuOpen ? 'block' : 'hidden'
        }`}
        style={{ right: 0, left: 'auto', background: "linear-gradient(180deg, #712C30 0%, #712C30 100%)" }}
      >
        <div className="justify-center flex flex-col w-full mx-auto h-auto items-center">
          <ul className="gap-8 items-center flex flex-col list-none">
            <li 
              className={`text-white text-base hover:text-yellow-200 ${
                pathMatchRoute("#home") ? "font-bold border-b-2 border-yellow-200" : ""
              }`}
              onClick={() => handleNavigate("#home")}
            >
              Home
            </li>
            <li 
              className={`text-white text-base hover:text-yellow-200 ${
                pathMatchRoute("#services") ? "font-bold border-b-2 border-yellow-200" : ""
              }`}
              onClick={() => handleNavigate("#services")}
            >
              Services
            </li>
            <li 
              className={`text-white text-base hover:text-yellow-200 ${
                pathMatchRoute("#blog") ? "font-bold border-b-2 border-yellow-200" : ""
              }`}
              onClick={() => handleNavigate("#blog")}
            >
              Blog
            </li>
          </ul>
          <div className="flex flex-col gap-3 items-center mt-8">
            <button
              className="cursor-pointer px-5 py-2 bg-transparent border-white border-2 text-sm font-semibold text-white rounded-md hover:bg-white hover:text-[#712C30]"
              onClick={() => handleNavigate("#contact")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;