import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import MobileMenu from "./Mobilemenu";
import Logo from "../assets/logo.png";

function Navbar() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const location = useLocation();

    // Function to check if the current hash matches the route
    const pathMatchRoute = (hash) => {
        return hash === location.hash;
    };

    // Track scroll position and update state
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Smooth scroll to section
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div
            className={`${
                scrollPosition > 0 ? "bg-white" : "bg-transparent"
            } shadow-sm fixed w-full top-0 z-40 py-5 transition-colors duration-300 ease-in-out`}
        >
            <div className="navbar max-w-6xl mx-auto md-down:mx-5">
                <div className="w-full flex justify-between items-center">
                    <div className="logo">
                        <h2 className="text-[30px]">
                            <Link to="/">
                                <div className="w-[100px]">
                                    <img className="w-full" src={Logo} alt="Logo" />
                                </div>
                            </Link>
                        </h2>
                    </div>
                    <div className="menu">
                        <ul className="hidden sm-custom:flex gap-10">
                            <li
                                className={`cursor-pointer py-1 text-md font-medium text-black border-b-[3px] border-b-transparent ${
                                    pathMatchRoute("#") && "text-[#712C30] font-semibold"
                                }`}
                                onClick={() => scrollToSection("home")}
                            >
                                Home
                            </li>
                            <li
                                className={`cursor-pointer py-1 text-md font-medium text-black border-b-[3px] border-b-transparent ${
                                    pathMatchRoute("#services") && "text-[#712C30] font-semibold"
                                }`}
                                onClick={() => scrollToSection("services")}
                            >
                                Services
                            </li>
                            <li
                                className={`cursor-pointer py-1 text-md font-medium text-black border-b-[3px] border-b-transparent ${
                                    pathMatchRoute("#blog") && "text-[#712C30] font-semibold"
                                }`}
                                onClick={() => scrollToSection("blog")}
                            >
                                Blog
                            </li>
                        </ul>
                    </div>

                    <div className="flex gap-2 md-down:hidden">
                        <button
                            className="cursor-pointer px-5 py-2 bg-[#712C30] text-sm font-semibold text-white rounded-md"
                            onClick={() => scrollToSection("contact")}
                        >
                            Contact Us
                        </button>
                    </div>

                    <div className="flex xl:hidden">
                        <MobileMenu />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
